<template>
  <div class='add-bank-account-main-wrapper'>
    <a-form ref="formRef">
    <a-row style='display: unset'>
      <a-col>
        <div style='margin-top: 100px'>
          <!-- <div>
            <img src='../../../assets/panImage.png' />
          </div> -->
          <div>
            <div class='add-bank-account-title'>Let's add your bank account</div>

            <!-- <div class='search-and-add-your-bank-fields-wrapper'>
              <a-form-item v-bind='validateInfos.name'>
              <a-select
                v-model:value='modelRef.name'
                :size='size'
                style='width: 100%; text-align: initial'
                class='select-field'
                :options='bankNameOptions'
                placeholder='Select your bank'
              ></a-select>
              </a-form-item>
            </div> -->
            <div>
            <div class='enter-bank-ifcs-wrapper'>
              <a-form-item v-bind='validateInfos.ifsc'>
              <a-input
                  class='input-field'
                  v-model:value='modelRef.ifsc'
                  placeholder='Enter IFSC code'
                  :disabled='disable'
                >
                </a-input>
              </a-form-item>
            </div>
            <div class='find-bank-name-btn-wrapper'>
              <a-button @click="findBankName" :disabled="disableIfscButton" type='primary'
                >Fetch Bank Details</a-button
              >
            </div>
            </div>
            <div>
            <!-- <div style='text-align: initial; margin-top: 20px'>
              Select your bank
            </div> -->
            <div v-if="ifcsCodeResponse" class='bank-details-card-select-wrapper'>
              <div><span style='font-weight: 600'>Branch Name: </span>
                {{ bankDetails.bankName }}</div>
              <div><span style='font-weight: 600'>Branch Address: </span>
                {{ bankDetails.branchAddress }}</div>
              <div><span style='font-weight: 600'>IFSC: </span>{{ bankDetails.ifsc }}</div>
              <div><span style='font-weight: 600'>MICR: </span>{{ bankDetails.micr }}</div>
            </div>
            <div class="account-type-text" style="display: flex">
              <div class="account-type-radio-text">
                Account Type
              </div>
            <div style="margin-left:auto">
              <a-form-item v-bind='validateInfos.type'>
                <a-radio-group
                    v-model:value="modelRef.type"
                  >
                    <a-radio :value='TYPE.ACCOUNT_TYPE.SAVINGS' style="font-size: 16px"
                      >Savings</a-radio
                    >
                    <a-radio :value='TYPE.ACCOUNT_TYPE.CURRENT' style="font-size: 16px">
                      Current</a-radio
                    >
                </a-radio-group>
              </a-form-item>
            </div>
            </div>
            <div class='account-number-confirm-wrapper'>
              <div style='width: 100%; margin-right: 10px'>
                <a-form-item v-bind='validateInfos.account'>
                <a-input
                  @copy.prevent
                  @paste.prevent
                  type='password'
                  class='input-field'
                  v-model:value='modelRef.account'
                  placeholder='Enter account number'
                  :disabled='disable'
                >
                </a-input>
                </a-form-item>
              </div>
              <div style='width: 100%'>
                <a-form-item v-bind='validateInfos.verifyAccount'>
                <a-input
                  @paste.prevent
                  class='input-field'
                  v-model:value='modelRef.verifyAccount'
                  placeholder='Confirm account number'
                  :disabled='disable'
                >
                </a-input>
                </a-form-item>
              </div>
            </div>
            </div>
            <div class='verify-my-bank-account-btn-wrapper'>
              <a-button type='primary' @click='onClickVerify' :loading="loading"
                >Verify my bank account</a-button
              >
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
    </a-form>
  </div>
</template>
<script>
// import { useRouter } from 'vue-router';
import {
  onMounted,
  ref,
  reactive,
  computed,
} from 'vue';
import { Form } from 'ant-design-vue';
import { useStore } from 'vuex';
import axios from 'axios';
// import STAGES from '../../../constants/stages';
import TYPE from '../../../constants/constant';
import services from '../../../services/apis';
import Operations from '../../../utils/operations';

const { useForm } = Form;

export default {
  components: {
  },
  setup() {
    const store = useStore();
    const disable = ref(false);
    const loading = ref(false);
    const ifcsCodeResponse = ref(false);
    const bankDetails = ref({});
    const responsesStatusCode = ref();
    const modelRef = reactive({
      name: '',
      ifsc: '',
      account: '',
      verifyAccount: '',
      type: TYPE.ACCOUNT_TYPE.SAVINGS,
    });
    onMounted(() => {
      window.scrollTo(0, 0);
      // const currentStage = computed(
      //   () => store.getters['onboardingStore/getCurrentStage'],
      // ).value;
      // if (currentStage !== STAGES.STAGE_TYPE.BANK_DETAILS) {
      services
        .getStages()
        .then((response) => {
          store.dispatch('onboardingStore/updateStage', response.data.data.stage);
          if (response.data.data.prefill.bankDetails) {
            ifcsCodeResponse.value = true;
            responsesStatusCode.value = 200;
            const banResponse = response.data.data.prefill.bankDetails;
            bankDetails.value.bankName = banResponse.name;
            bankDetails.value.branchAddress = banResponse.name;
            modelRef.ifsc = banResponse.ifsc;
            bankDetails.value.ifsc = banResponse.ifsc;
            bankDetails.value.micr = banResponse.micr;
            modelRef.account = banResponse.account;
            modelRef.verifyAccount = banResponse.account;
            modelRef.type = banResponse.type;
          }
        })
        .catch(() => {
        });
      // }
    });
    // const router = useRouter();
    // const bankNameOptions = ref([
    //   {
    //     value: 'Central Bank of India',
    //     label: 'Central Bank of India',
    //   },
    //   {
    //     value: 'State Bank of India',
    //     label: 'State Bank of India',
    //   },
    //   {
    //     value: 'Standard Chartered Bank',
    //     label: 'Standard Chartered Bank',
    //   },
    //   {
    //     value: 'HSBC BANK INDIA',
    //     label: 'HSBC BANK INDIA',
    //   },
    //   {
    //     value: 'ICICI Bank Ltd',
    //     label: 'ICICI Bank Ltd',
    //   },
    //   {
    //     value: 'Citi Bank',
    //     label: 'Citi Bank',
    //   },
    //   {
    //     value: 'HDFC Bank',
    //     label: 'HDFC Bank',
    //   },
    // ]);
    const formRef = ref();

    const validatePass = async (_rule, value) => {
      if (value === '') {
        return Promise.reject(new Error('Please enter'));
      }
      return Promise.resolve();
    };

    const validatePass2 = async (_rule, value) => {
      if (value === '') {
        return Promise.reject(new Error('Re enter account number'));
      }
      if (value !== modelRef.account) {
        return Promise.reject(new Error('Account number does not match!'));
      }
      return Promise.resolve();
    };

    const rulesRef = reactive({
      // name: [
      //   {
      //     required: true,
      //     message: 'Please select',
      //   },
      // ],
      ifsc: [
        {
          required: true,
          message: 'Enter IFSC code',
        },
      ],
      type: [
        {
          required: true,
          message: 'Select account type',
        },
      ],
      account: [
        {
          required: true,
          message: 'Enter account number',
          validator: validatePass,
          trigger: 'change',
        },
      ],
      verifyAccount: [
        {
          required: true,
          message: 'Enter account number',
          validator: validatePass2,
          trigger: 'change',
        },
      ],
    });
    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef,
    );
    const findBankName = () => {
      let bankObj = {};
      axios.get(`https://ifsc.razorpay.com/${modelRef.ifsc}`)
        .then((response) => {
          responsesStatusCode.value = response.status;
          const bankData = response.data;
          ifcsCodeResponse.value = true;
          bankObj = {
            bankName: bankData.BANK ? bankData.BANK : '-',
            branchAddress: bankData.BRANCH ? bankData.BRANCH : '-',
            ifsc: bankData.IFSC ? bankData.IFSC : '-',
            micr: bankData.MICR ? bankData.MICR : '-',
          };
          Object.assign(bankDetails.value, bankObj);
        })
        .catch(() => {
          ifcsCodeResponse.value = false;
          responsesStatusCode.value = null;
          const message = 'Validation error';
          const description = 'Please provide a valid IFSC code. No matching details found.';
          Operations.notificationError(message, description);
        });
    };
    const disableIfscButton = computed(() => {
      if (modelRef.ifsc.length === 0) {
        return true;
      }
      return false;
    });
    const onClickVerify = () => {
      if (responsesStatusCode.value === 200) {
        validate().then(() => {
          const payload = {
            name: bankDetails.value.bankName,
            branch: bankDetails.value.branchAddress,
            micr: bankDetails.value.micr,
            account: modelRef.account,
            ifsc: bankDetails.value.ifsc,
            type: modelRef.type,
          };
          loading.value = true;
          disable.value = true;
          services
            .addBankManually(payload)
            .then((response) => {
              loading.value = false;
              store.dispatch('onboardingStore/updateStage', response.data.data.stage);
              store.dispatch('onboardingStore/updateRouteName', '/bank/details');
              // router.push('/bank/verification');
            })
            .catch(() => {
              loading.value = false;
              disable.value = false;
              modelRef.account = '';
              modelRef.verifyAccount = '';
            });
        });
      } else {
        const message = 'Validation error';
        const description = 'Provide your bank details';
        Operations.notificationError(message, description);
      }
    };
    return {
      onClickVerify,
      value1: ref(),
      checked: ref(false),
      // bankNameOptions,
      validateInfos,
      resetFields,
      modelRef,
      formRef,
      ifcsCodeResponse,
      findBankName,
      bankDetails,
      disableIfscButton,
      disable,
      loading,
      TYPE,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/addBankAccount.scss';
</style>
